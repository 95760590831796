import React, { useState, useEffect } from 'react';
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";
import particlesConfig from "../assets/particles.json";
import "./home.css";
import me from "../assets/me.png";


const Backdrop = () => {
  const [init, setInit] = useState(false);

  // this should be run only once per application lifetime
  useEffect(() => {
    initParticlesEngine(async (engine) => {
      // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
      // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
      // starting from v2 you can add only the features you need reducing the bundle size
      //await loadAll(engine);
      //await loadFull(engine);
      await loadSlim(engine);
      //await loadBasic(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = (container) => {
    console.log(container);
  };

  if (init) {
    return (
      <Particles
        id="tsparticles"
        options={particlesConfig}
        particlesLoaded={particlesLoaded}
      />
    );
  }

  return <></>;
};

const MyComponent = () => {
  return (
    <div>
      <div className='contentDiv'>
        <div>
          <img className="meImage" src={me} alt="me"></img>
        </div>
        <h1><b>benwurster.com</b> is going through a brief period of transition</h1>
        <h2>If you'd like to get in touch, please reach out at <b><a href="mailto:wursterben17@gmail.com">wursterben17@gmail.com</a></b>!</h2>
      </div>
      <div className="backdrop">
        <Backdrop />
      </div>
    </div>
  );
};

export default MyComponent;
